:root {
    --default-navbar-height: 40px;
    --default-description-height: 40px;
    --default-input-height: 48px;
    --default-terms-height: 0px;
    --default-quick-connect-chat-body-height:  calc(100vh -
        var(--default-navbar-height) -
        var(--default-input-height) -
        var(--default-terms-height) -
        var(--default-description-height) - 120px);
    --default-quick-connect-chat-body-mobile-height:  calc(100vh -
        var(--default-navbar-height) -
        var(--default-input-height) -
        var(--default-description-height) - 15px);
    --default-body-height:  calc(100vh -
        (var(--default-navbar-height) + 10px +
        var(--default-input-height) +
        var(--default-terms-height)) -
        var(--default-description-height));
    --default-mobile-body-height:  calc(100vh -
                            (var(--default-navbar-height) + 35px +
                            var(--default-input-height) +
                            var(--default-terms-height)) -
                            var(--default-description-height));
  }
